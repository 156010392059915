const words = [
  "an innovative idea?",
  "a bold vision?",
  "a game-changing concept?",
  "an extraordinary plan?",
  "a groundbreaking thought?",
  "a brilliant spark?",
  "a fresh perspective?",
];
let i = 0;
let j = 0;
let currentWord = "";
let isDeleting = false;

function type() {
  let timeout = 0;
  currentWord = words[i];
  if (isDeleting) {
    document.getElementById("typewriter").textContent = currentWord.substring(
      0,
      j - 1
    );
    j--;
    if (j == 0) {
      isDeleting = false;
      i++;
      if (i == words.length) {
        i = 0;
      }
    }
  } else {
    document.getElementById("typewriter").textContent = currentWord.substring(
      0,
      j + 1
    );
    j++;
    if (j == currentWord.length) {
      isDeleting = true;
      timeout = 1600;
    }
  }
  setTimeout(type, 40 + timeout);
}

type();
